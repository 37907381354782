import { Component } from '@angular/core';
import { SvgIconComponent } from '../../shared/ui/utils/svg-icon/svg-icon.component';
import { Store } from '@ngrx/store';
import { AsyncPipe, CommonModule } from '@angular/common';
import { DashboardPageActions } from '../../dashboard/state/actions';
import { combineLatest, map } from 'rxjs';
import * as selector from '../../dashboard/state';

@Component({
  selector: 'app-module-tabs',
  templateUrl: './module-tabs.component.html',
  styleUrls: ['./module-tabs.component.scss'],
  standalone: true,
  imports: [SvgIconComponent, AsyncPipe, CommonModule],
})
export class ModuleTabsComponent {
  currentModules$ = this.store.select(selector.getCurrentLevelModules);
  currentModuleIndex$ = this.store.select(selector.getCurrentModuleIndex);
  moduleCount$ = this.store.select(selector.countAllModules);

  vm$ = combineLatest([this.currentModules$, this.currentModuleIndex$, this.moduleCount$]).pipe(
    map(([modules, currentModuleIndex, moduleCount]) => ({
      modules,
      currentModuleIndex,
      moduleCount: moduleCount
    })),
  );

  constructor(private store: Store) {}

  moduleChange(direction?: -1 | 1, moduleIndex?: number) {
    this.store.dispatch(
      DashboardPageActions.moduleChange({ direction, moduleIndex }),
    );
  }

  displayIndex(index: number) {
    return index + 1;
  }
}
