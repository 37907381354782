import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as selector from '../state';
import { AsyncPipe, CommonModule } from '@angular/common';
import { VideoConfig } from '../../shared/services/api/models/course-response';
import { Observable } from 'rxjs';
import { SvgIconComponent } from '../../shared/ui/utils/svg-icon/svg-icon.component';
import * as actions from '../state/actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-video-player',
  standalone: true,
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  imports: [AsyncPipe, SvgIconComponent, CommonModule],
})
export class VideoPlayerComponent implements AfterViewInit {
  currentVideo$: Observable<VideoConfig> = this.store.select(
    selector.getCurrentVideo,
  );
  currentVideo: VideoConfig = {} as VideoConfig;
  @ViewChild('resourceVideo', { static: false })
  resourceVideo!: ElementRef<HTMLVideoElement>;
  countdown: number | null = null;

  _destroy: DestroyRef = inject(DestroyRef);

  constructor(private store: Store) {}

  ngAfterViewInit(): void {
    if (this.resourceVideo?.nativeElement) {
      this.resourceVideo.nativeElement.addEventListener('ended', () => {
        this.onVideoEnded();
      });
    }

    this.currentVideo$
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe((video) => {
        this.currentVideo = video;
      });
  }

  onVideoEnded() {
    if (!this.currentVideo.completed) {
      this.store.dispatch(actions.DashboardPageActions.userCompletedVideo());
    }

    // Load next video if there are more to play; otherwise, close
    if (this.currentVideo.isLastVideo) {
      this.close();
    } else {
      this.loadNextVideo();
    }
  }

  loadNextVideo() {
    this.countdown = 3; // Start countdown at 3 seconds

    const intervalId = setInterval(() => {
      if (this.countdown && this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(intervalId);
        this.store.dispatch(
          actions.DashboardPageActions.loadNextVideo({
            videoIndex: this.currentVideo.currentVideoIndex! + 1,
          }),
        );
        this.countdown = null;
      }
    }, 1000);
  }

  close() {
    this.store.dispatch(actions.DashboardPageActions.userClosedVideo());
  }
}
