import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { CourseModule, CourseState, ModuleQuiz } from './models/state-models';
import { VideoConfig } from '../../shared/services/api/models/course-response';
import { checkForCompleteLevels, getNextActivity, updateCourseDataWithTraineeProgress } from './functions/reducer-functions';

const initialState: CourseState = {
  traineeProgressId: '',
  culture: '',
  loading: false,
  userName: '',
  modules: [],
  currentModule: {} as CourseModule,
  currentVideo: {} as VideoConfig,
  videoPlayed: NaN,
  showModuleTabs: true,
  error: '',
  activityTracker: {
    redirect: '/home',
    text: 'Module is complete',
    canDownloadCertificate: false,
    certificateLevelId: NaN,
    type: '',
    showQuizRetakeButton: false,
  },
  certificates: [],
  progressResults: [],
  cookiePreferencesAccepted: localStorage.getItem('UnderstandingDyslexiaCookieConsent') === 'true',
  isCookiesPath: false,
};

export const courseDashboardReducer = createReducer<CourseState>(
  initialState,

  on(actions.DashboardPageActions.initApp, (): CourseState => {
    return {
      ...initialState,
      loading: true,
    };
  }),

  on(
    actions.DashboardPageActions.showModuleTabs,
    (state, action): CourseState => {
      return {
        ...state,
        showModuleTabs: action.showTabs,
      };
    },
  ),

  on(
    actions.DashboardPageActions.moduleChange,
    (state, action): CourseState => {
      let moduleIndex =
        action.moduleIndex !== undefined
          ? action.moduleIndex
          : state.currentModule.moduleIndex;

      if (action.moduleIndex === undefined) {
        if (action.direction === -1) {
          moduleIndex = Math.max(0, state.currentModule.moduleIndex - 1);
        } else if (action.direction === 1) {
          moduleIndex = Math.min(
            state.modules.length - 1,
            state.currentModule.moduleIndex + 1,
          );
        }
      }

      const newCurrentModule = state.modules[moduleIndex];

      const updateActivityModuleChange = getNextActivity(
        newCurrentModule,
        initialState,
      );

      sessionStorage.setItem('appSessionState', JSON.stringify({currentModuleIndex: newCurrentModule.moduleIndex, traineeProgressId: state.traineeProgressId}));

      return {
        ...state,
        currentModule: newCurrentModule,
        activityTracker: updateActivityModuleChange,
      };
    },
  ),

  on(
    actions.DashboardPageActions.moduleChangeViaMenu,
    (state, action): CourseState => {
      const newCurrentModule = state.modules[action.moduleIndex];

      const updateActivityModuleChange = getNextActivity(
        newCurrentModule,
        initialState,
      );

      sessionStorage.setItem('appSessionState', JSON.stringify({currentModuleIndex: newCurrentModule.moduleIndex, traineeProgressId: state.traineeProgressId}));

      return {
        ...state,
        currentModule: newCurrentModule,
        activityTracker: updateActivityModuleChange,
      };
    },
  ),

  on(
    actions.DashboardPageActions.trainingVideoPlayed,
    (state, action): CourseState => {
      return {
        ...state,
        videoPlayed: action.videoId,
      };
    },
  ),

  on(
    actions.DashboardPageActions.activityTrackerDispatchedQuizRedirect,
    (state): CourseState => {
      return {
        ...state,
      };
    },
  ),

  on(
    actions.DashboardPageActions.userClickedPlayVideo,
    actions.DashboardPageActions.loadNextVideo,
    (state, action): CourseState => {
      return {
        ...state,
        currentVideo: {
          ...state.currentModule.videos[action.videoIndex],
          currentVideoIndex: action.videoIndex,
          isLastVideo: action.videoIndex + 1 >= state.currentModule.videos.length,
        },
      };
    },
  ),

  on(actions.DashboardPageActions.userClosedVideo, (state): CourseState => {
    return {
      ...state,
      currentVideo: {} as VideoConfig,
    };
  }),

  //***************************** API-DASHBOARD-ACTIONS *************************************************

  on(
    actions.DashboardApiActions.getCourseContentSuccess,
    (state, action): CourseState => {
      const courseDataWithProgress = updateCourseDataWithTraineeProgress(
        action.courseModules,
        state.progressResults,
      );

      let sessionStorageModuleIndex = null;
      if (
        action.sessionStorage &&
        state.traineeProgressId === action.sessionStorage.traineeProgressId
      ) {
        if (action.sessionStorage.currentModuleIndex >= 0)
          sessionStorageModuleIndex = action.sessionStorage.currentModuleIndex;
      } else {
        sessionStorage.clear();
      }

      const { updatedModules, certificates } = checkForCompleteLevels(
        courseDataWithProgress,
      );

      const nextAvailableModuleIndex = updatedModules.findIndex(
        (moduleItem) => moduleItem.moduleCompleted === false,
      );

      //* module is picked by checking if we have anything in storage first
      //* otherwise defaults to next uncompleted module
      //* if all modules are completed, defaults to first module

      const nextModuleIndex =  sessionStorageModuleIndex !== null ? sessionStorageModuleIndex : nextAvailableModuleIndex !== -1 ? nextAvailableModuleIndex : 0;

      const nextActivityTracker = getNextActivity(
        updatedModules[nextModuleIndex],
        initialState,
      );

      return {
        ...state,
        activityTracker: nextActivityTracker,
        modules: updatedModules,
        currentModule: updatedModules[nextModuleIndex],
        certificates,
        loading: false,
      };
    },
  ),

  on(
    actions.DashboardApiActions.getCourseContentFailed,
    (state, action): CourseState => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    },
  ),

  on(
    actions.DashboardApiActions.getTraineeProgressSuccess,
    (state, action): CourseState => {
      return {
        ...state,
        culture: action.culture,
        userName: action.userName,
        progressResults: action.progressResults,
        traineeProgressId: action.traineeProgressId,
      };
    },
  ),

  on(
    actions.DashboardApiActions.getTraineeProgressFailed,
    (state, action): CourseState => {
      return {
        ...initialState,
        loading: false,
        error: action.error,
        culture: action.culture,
      };
    },
  ),

  on(
    actions.DashboardApiActions.videoUpdateFailed,
    (state, action): CourseState => {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    },
  ),

  //***************************** QUIZ-ACTIONS *************************************************

  on(actions.QuizPageActions.showModuleTabs, (state, action): CourseState => {
    return {
      ...state,
      showModuleTabs: action.showTabs,
    };
  }),

  on(actions.QuizPageActions.checkAnswer, (state, action): CourseState => {
    const updatedAnswers = state.currentModule.moduleQuiz.quizAnswers.map(
      (row, rIndex) =>
        row.map((item, cIndex) =>
          rIndex === state.currentModule.moduleQuiz.currentQuestionIndex &&
          cIndex === action.answerIndex
            ? { ...item, questionUsed: true }
            : item,
        ),
    );

    const hasUsedAnswers = updatedAnswers[
      state.currentModule.moduleQuiz.currentQuestionIndex
    ].some((item) => item.questionUsed);

    const currentAnswer =
      updatedAnswers[state.currentModule.moduleQuiz.currentQuestionIndex][
        action.answerIndex
      ];

    const canMoveToNextQuesiton = state.currentModule.moduleQuiz
      .canMoveToNextQuesiton
      ? true
      : currentAnswer.questionUsed && currentAnswer.valid;

    //- tracket wheher quiz can be completed
    const currentQuestionIndex = Math.min(
      state.currentModule.moduleQuiz.currentQuestionIndex + 1,
      state.currentModule.moduleQuiz.quizQuestions.length,
    );
    const submitQuizProgress =
      currentQuestionIndex ===
        state.currentModule.moduleQuiz.quizQuestions.length &&
      canMoveToNextQuesiton
        ? true
        : false;

    const moduleQuizUpdated: ModuleQuiz = {
      ...state.currentModule.moduleQuiz,
      quizAnswers: updatedAnswers,
      currentAnswerIndex: action.answerIndex,
      hasUsedAnswers,
      canMoveToNextQuesiton,
      submitQuizProgress,
    };

    const currentModuleUpdated = {
      ...state.currentModule,
      moduleQuiz: moduleQuizUpdated,
    };

    return {
      ...state,
      modules: state.modules.map((item) => {
        if (item.moduleId === currentModuleUpdated.moduleId) {
          return currentModuleUpdated;
        }
        return item;
      }),
      currentModule: currentModuleUpdated,
    };
  }),

  on(actions.QuizPageActions.nextQuestion, (state): CourseState => {
    const currentQuestionIndex = Math.min(
      state.currentModule.moduleQuiz.currentQuestionIndex + 1,
      state.currentModule.moduleQuiz.quizQuestions.length,
    );

    const hasUsedAnswers = state.currentModule.moduleQuiz.quizAnswers[
      currentQuestionIndex
    ].some((item) => item.questionUsed);

    const moduleQuizUpdated: ModuleQuiz = {
      ...state.currentModule.moduleQuiz,
      currentQuestionIndex,
      hasUsedAnswers,
      canMoveToNextQuesiton: false,
    };

    const currentModuleUpdated = {
      ...state.currentModule,
      moduleQuiz: moduleQuizUpdated,
    };

    return {
      ...state,
      modules: state.modules.map((item) => {
        if (item.moduleId === currentModuleUpdated.moduleId) {
          return currentModuleUpdated;
        }
        return item;
      }),
      currentModule: currentModuleUpdated,
    };
  }),

  on(actions.QuizApiActions.quizUpdateFailed, (state, action): CourseState => {
    return {
      ...state,
      error: action.error,
    };
  }),
  //***************************** COOKIES-ACTIONS *************************************************
  on(actions.DashboardPageActions.acceptCookies, (state): CourseState => {
    return {
      ...state,
      cookiePreferencesAccepted: true,
    };
  }),

  on(
    actions.DashboardPageActions.updatePageHeader,
    (state, action): CourseState => {
      return {
        ...state,
        isCookiesPath: action.isCookiesPath,
      };
    },
  ),
);
