import { VideoConfig } from '../../../shared/services/api/models/course-response';
import { TraineeModuleProgress } from '../../services/models/trainee-progress.model';
import {
  CourseModule,
  ActivityTracker,
  CourseContentAdapted,
  Certificate,
  CourseState,
} from '../models/state-models';

export function getNextActivity(
  currentModule: CourseModule,
  initialState: CourseState,
): ActivityTracker {
  const nextVideoIndex = currentModule.videos?.findIndex(
    (nextVideo) => !nextVideo.completed,
  );

  const hasQuizToComplete =
    currentModule.videos?.every((item) => item.completed) &&
    !currentModule.moduleCompleted;

  const getCertificate = currentModule.levelCompleted;
  const showQuizRetakeButton = currentModule.moduleCompleted;

  let activityTrackerUpdated: ActivityTracker = initialState.activityTracker;

  if (getCertificate) {
    activityTrackerUpdated = {
      canDownloadCertificate: true,
      certificateLevelId: currentModule.levelId,
      redirect: '',
      text: 'Click to download level certificate',
      type: 'certificate',
      showQuizRetakeButton,
    };
  } else if (hasQuizToComplete) {
    activityTrackerUpdated = {
      canDownloadCertificate: false,
      certificateLevelId: NaN,
      redirect: '/quiz',
      text: `Click here to take the quiz`,
      type: 'quiz',
      showQuizRetakeButton,
    };
  } else if (nextVideoIndex >= 0) {
    const nextVideoTitle = currentModule.videos[nextVideoIndex].title;

    activityTrackerUpdated = {
      redirect: '',
      certificateLevelId: NaN,
      canDownloadCertificate: false,
      text: `Watch video ${nextVideoIndex + 1} - ${nextVideoTitle}`,
      type: 'video',
      nextVideoIndex: nextVideoIndex,
      showQuizRetakeButton,
    };
  } else {
    activityTrackerUpdated = {
      redirect: showQuizRetakeButton ? '/quiz' : '/home',
      certificateLevelId: NaN,
      text: `Module ${currentModule.moduleIndex + 1} is complete`,
      canDownloadCertificate: false,
      type: '',
      showQuizRetakeButton,
    };
  }

  return activityTrackerUpdated;
}

export function updateCourseDataWithTraineeProgress(
  courseModules: CourseModule[],
  traineeProgress: TraineeModuleProgress[],
): CourseModule[] {
  return courseModules.map((currentModule) => {
    let updatedModule: CourseModule = { ...currentModule };

    const hasProgress = traineeProgress.find(
      (progress) =>
        progress.moduleId === updatedModule.moduleId &&
        (progress.videos.length > 0 || progress.quiz.completedAt),
    );

    if (hasProgress) {
      const updatedVideos = updateVideoProgress(updatedModule, hasProgress);
      updatedModule = {
        ...updatedModule,
        videos: updatedVideos,
        moduleCompleted: hasProgress.isModuleComplete,
        levelCompleted: Boolean(hasProgress.levelCompleted),
      };
    }

    return updatedModule;
  });
}

export function updateVideoProgress(
  moduleToUpdate: CourseModule,
  progress: TraineeModuleProgress,
): VideoConfig[] {
  return moduleToUpdate.videos.map((item) => ({
    ...item,
    completed: progress.videos.some(
      (p) => p.videoId === item.id && p.completedAt,
    ),
  }));
}

export function checkForCompleteLevels(
  modulesCleanedWithProgress: CourseContentAdapted[],
): {
  updatedModules: CourseContentAdapted[];
  certificates: Certificate[];
} {
  const levelOneComplete = modulesCleanedWithProgress
    .filter((item) => item.level.includes('1'))
    .every((item) => item.moduleCompleted);

  const levelTwoComplete = modulesCleanedWithProgress
    .filter((item) => item.level.includes('2'))
    .every((item) => item.moduleCompleted);

  const levelThreeComplete = modulesCleanedWithProgress
    .filter((item) => item.level.includes('3'))
    .every((item) => item.moduleCompleted);

  const updatedModulesWithLevels = modulesCleanedWithProgress.map((item) => {
    let updatedItem = item;
    if (item.level.includes('1')) {
      updatedItem = {
        ...updatedItem,
        levelCompleted: levelOneComplete,
      };
    }
    if (item.level.includes('2')) {
      updatedItem = {
        ...updatedItem,
        levelCompleted: levelTwoComplete,
      };
    }
    if (item.level.includes('3')) {
      updatedItem = {
        ...updatedItem,
        levelCompleted: levelThreeComplete,
      };
    }
    return updatedItem;
  });

  const [levelOneCertificate]: Certificate[] = updatedModulesWithLevels
    .filter((item) => item.level.includes('1'))
    .map(
      (item): Certificate => ({
        isCertificateAvailable: item.levelCompleted,
        level: item.level,
        levelName: item.levelName,
        levelId: item.levelId,
      }),
    );
  const [levelTwoCertificate]: Certificate[] = updatedModulesWithLevels
    .filter((item) => item.level.includes('2'))
    .map(
      (item): Certificate => ({
        isCertificateAvailable: item.levelCompleted,
        level: item.level,
        levelName: item.levelName,
        levelId: item.levelId,
      }),
    );
  const [levelThreeCertificate]: Certificate[] = updatedModulesWithLevels
    .filter((item) => item.level.includes('3'))
    .map(
      (item): Certificate => ({
        isCertificateAvailable: item.levelCompleted,
        level: item.level,
        levelName: item.levelName,
        levelId: item.levelId,
      }),
    );

  const certificatesAvailable: Certificate[] = [
    levelOneCertificate,
    levelTwoCertificate,
    levelThreeCertificate,
  ];

  return {
    updatedModules: updatedModulesWithLevels,
    certificates: certificatesAvailable,
  };
}
