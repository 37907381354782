import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CourseState,
  CourseModule,
  ModuleQuiz,
  ActivityTracker,
  Certificate,
} from './models/state-models';
import { VideoConfig } from '../../shared/services/api/models/course-response';

const selector = <T>(mapping: (state: CourseState) => T) =>
  createSelector(selectDashboardFeature, mapping);

export const selectDashboardFeature =
  createFeatureSelector<CourseState>('dashboard');

export const getDashboardState = selector(
  (state: CourseState): CourseState => state,
);

export const getCurrentLevel = selector(
  (state: CourseState): string => state.currentModule.level,
);

export const getCurrentLevelModules = selector(
  (state: CourseState): CourseModule[] =>
    state.modules.filter(
      (moduleItem) => moduleItem.level === state.currentModule.level,
    ),
);

export const getAllModules = selector(
  (state: CourseState): CourseModule[] => state.modules,
);

export const countAllModules = selector(
  (state: CourseState): number => state.modules.length,
);

export const getModuleQuiz = selector(
  (state: CourseState): ModuleQuiz => state.currentModule.moduleQuiz,
);

export const getActivityTracker = selector(
  (state: CourseState): ActivityTracker => state.activityTracker,
);

export const getCurrentModuleId = selector(
  (state: CourseState): number => state.currentModule.moduleId,
);

export const getCurrentModule = selector(
  (state: CourseState): CourseModule | null => state.currentModule,
);

export const showModuleTabs = selector(
  (state: CourseState): boolean => state.showModuleTabs,
);

export const getCurrentModuleIndex = selector(
  (state: CourseState): number => state.currentModule.moduleIndex,
);

export const getVideos = selector(
  (state: CourseState): CourseModule['videos'] =>
    state.currentModule?.videos || [],
);

export const getCertificates = selector(
  (state: CourseState): Certificate[] => state.certificates,
);

export const getUserName = selector(
  (state: CourseState): string => state.userName,
);

export const getErrorMessage = selector(
  (state: CourseState): string => state.error,
);

export const getCulture = selector(
  (state: CourseState): string => state.culture,
);

export const getTraineeProgressId = selector(
  (state: CourseState): string => state.traineeProgressId,
);

export const getCookiePreferences = selector(
  (state: CourseState): boolean => state.cookiePreferencesAccepted,
);

export const isCookiePath = selector(
  (state: CourseState): boolean => state.isCookiesPath,
);

export const getCurrentVideo = selector(
  (state: CourseState): VideoConfig => state.currentVideo,
);