import { Inject, Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  map,
  catchError,
  of,
  switchMap,
  Observable,
  concatMap,
  withLatestFrom,
} from 'rxjs';
import { DashboardAdapterService } from '../services/dashboard-adapter.service';
import { ApiService } from '../../shared/services/api/api.service';
import { QueryResponse } from '../../shared/services/api/response-templates/query-response';
import { CourseResponse } from '../../shared/services/api/models/course-response';
import * as actions from './actions/index';
import * as selector from './../state';
import { Router } from '@angular/router';
import { ProgressResponse } from '../../shared/services/api/models/progress-response';
import { Action, Store } from '@ngrx/store';
import { saveAs } from '../../shared/functions/download-certificate';
import { DOCUMENT } from '@angular/common';
import { CommandResponse } from '../../shared/services/api/response-templates/command-response';
import { UnknownErrors } from '../../shared/services/api/response-templates/unknown-errors';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private adapterService: DashboardAdapterService,
    private api: ApiService,
    private router: Router,
    private store: Store,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  getTraineeProgress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        actions.DashboardPageActions.initApp,
        actions.QuizApiActions.quizUpdateSuccess,
        actions.DashboardPageActions.userClosedVideo,
      ),
      concatMap(() =>
        this.api.getTraineeProgress().pipe(
          map((res: QueryResponse<ProgressResponse>) => {
            if (res.isSuccess) {
              const adaptedModuleProgress =
                this.adapterService.adaptTraineeProgress(res.result);

              return actions.DashboardApiActions.getTraineeProgressSuccess({
                progressResults: adaptedModuleProgress,
                userName: res.result.firstName + ' ' + res.result.lastName,
                culture: res.result.culture,
                traineeProgressId: res.result.traineeProgressId,
              });
            } else {
              return actions.DashboardApiActions.getTraineeProgressFailed({
                error: 'Trainne Progress Failed',
                culture: res.result.culture,
              });
            }
          }),
          catchError((error) => {
            return of(
              actions.DashboardApiActions.getTraineeProgressFailed({
                error: error.statusDescription,
                culture: error.result.culture,
              }),
            );
          }),
        ),
      ),
    ),
  );

  getCourseContent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.DashboardApiActions.getTraineeProgressSuccess),
      withLatestFrom(this.store.select(selector.getCulture)),
      switchMap(([, culture]) =>
        this.api.getDashboard(culture).pipe(
          map((res: QueryResponse<CourseResponse>) => {
            const adapterCourseResponse =
              this.adapterService.adaptCourseDataToModules(res.result);

            const sessionState = this.adapterService.getSessionStorage();

            return actions.DashboardApiActions.getCourseContentSuccess({
              courseModules: adapterCourseResponse,
              sessionStorage: sessionState,
            });
          }),
          catchError((error: { message: string }) =>
            of(
              actions.DashboardApiActions.getCourseContentFailed({
                error: error.message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  activityHandler$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          actions.DashboardPageActions.activityTrackerDispatchedQuizRedirect,
          actions.QuizPageActions.quizRetakeNavigateHome,
        ),
        map((action) => {
          if (action.activity.redirect) {
            this.router.navigate([action.activity.redirect]);
          }
        }),
      ),
    { dispatch: false },
  );

  quizCompleted$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.QuizPageActions.submitQuizProgress),
      withLatestFrom(this.store.select(selector.getCulture)),
      switchMap(([action, culture]) =>
        this.api.saveQuizProgress(action.moduleId, culture).pipe(
          map((res: CommandResponse<CourseResponse, UnknownErrors>) => {
            if (res.isSuccess) {
              this.router.navigate(['/home']);
              sessionStorage.removeItem('appSessionState');
              return actions.QuizApiActions.quizUpdateSuccess({
                moduleCompletedId: action.moduleId,
              });
            } else {
              return actions.QuizApiActions.quizUpdateFailed({
                error: `Something went wrong completing quiz - moduleId: ${action.moduleId}`,
              });
            }
          }),
          catchError((error: { message: string }) =>
            of(
              actions.QuizApiActions.quizUpdateFailed({
                error: error.message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  videoWatched$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.DashboardPageActions.userCompletedVideo),
      withLatestFrom(this.store.select(selector.getCulture), this.store.select(selector.getCurrentVideo)),
      switchMap(([, culture, currentVideo]) =>
        this.api.saveVideoProgress(currentVideo.id, culture).pipe(
          map((response) => {
            if (response.isSuccess) {
              return actions.DashboardApiActions.videoUpdateSuccess();
            } else {
              return actions.DashboardApiActions.videoUpdateFailed({
                error: `Video Progress Save - Failed id: ${currentVideo.id}`,
              });
            }
          }),
          catchError((error: { message: string }) =>
            of(
              actions.DashboardApiActions.videoUpdateFailed({
                error: error.message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  downloadCertificate: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        actions.DashboardPageActions
          .activityTrackerDispatchedDownloadCertificate,
      ),
      withLatestFrom(
        this.store.select(selector.getCulture),
        this.store.select(selector.getCurrentModule),
      ),
      switchMap(([action, culture, currentModule]) =>
        this.api.downloadCertificate(action.levelId, culture).pipe(
          map((response) => {
            if (response) {
              const blob = new Blob([response], { type: 'application/pdf' });
              const levelName = currentModule?.levelName
                .trim()
                .replace(/ /g, '')
                .toLowerCase();
              const level = currentModule?.level
                .trim()
                .replace(/ /g, '')
                .toLowerCase()
                ?.trim()
                .replace(/ /g, '')
                .toLowerCase();
              saveAs(
                blob,
                this.document,
                `ud-${levelName}-${level}-certificate`,
                `${new Date().toJSON().slice(0, 10)}`,
              );

              return actions.DashboardApiActions.DownloadCertificateSuccess({
                certificateBlob: blob,
                levelId: action.levelId,
              });
            }
            return actions.DashboardApiActions.DownloadCertificateFail({
              error: 'Something went wrong getting Level certificate',
            });
          }),
          catchError((error: { message: string }) =>
            of(
              actions.DashboardApiActions.DownloadCertificateFail({
                error: error.message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  routeCheckOnModuleChanged: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.DashboardPageActions.moduleChangeViaMenuRouteCheck),
      map((action) => {
        if (action.path) {
          if (action.path.includes('quiz') || action.path.includes('cookies'))
            this.router.navigate(['/home']);
        }
        return actions.DashboardPageActions.moduleChangeViaMenu({
          level: action.level,
          moduleIndex: action.moduleIndex,
        });
      }),
    ),
  );
}
