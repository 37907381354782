import { Injectable } from '@angular/core';
import {
  AnswerConfig,
  CourseResponse,
  QuizConfig,
  VideoConfig,
} from '../../shared/services/api/models/course-response';

import { UNDERSTANDING_DYSLEXIA_ASSET_PATH } from '../../../environments/constants/environment-consts';
import {
  Levels,
  ModuleCompleted,
  ProgressResponse,
} from '../../shared/services/api/models/progress-response';
import { TraineeModuleProgress } from './models/trainee-progress.model';
import { Answer, Question } from '../../shared/services/api/models/quiz-model';
import { SessionStorage } from './models/session-storage.model';
import { CourseContentAdapted } from '../state/models/state-models';

@Injectable({
  providedIn: 'root',
})
export class DashboardAdapterService {
  private cndPath = UNDERSTANDING_DYSLEXIA_ASSET_PATH;

  adaptCourseDataToModules(courseData: CourseResponse): CourseContentAdapted[] {
    return courseData.levels
      .flatMap((lev): CourseContentAdapted[] => {
        const moduleMapped = lev.modules.flatMap(
          (moduleItem): CourseContentAdapted => ({
            levelId: lev.id,
            levelName: lev.title.includes('Level 1')
              ? 'Understanding Dyslexia'
              : lev.title.includes('Level 2')
                ? 'Science of Reading'
                : 'Spelling Rules',
            levelIcon: lev.title.includes('Level 1')
              ? 'understanding-dyslexia'
              : lev.title.includes('Level 2')
                ? 'science-of-reading'
                : 'spelling-rules',
            level: lev.title,
            moduleId: moduleItem.id,
            moduleName: moduleItem.subtitle,
            moduleIndex: 0,
            moduleTitle: moduleItem.title,
            moduleQuiz: {
              quizAnswers: this.adaptModuleQuizAnswers(moduleItem.quizConfig),
              quizQuestions: this.adaptModuleQuizQuestions(
                moduleItem.quizConfig,
              ),
              currentAnswerIndex: 0,
              canMoveToNextQuesiton: false,
              hasUsedAnswers: false,
              currentQuestionIndex: 0,
              quizComplete: false,
              submitQuizProgress: false,
            },
            videos: moduleItem.videos.map((videoItem: VideoConfig) => ({
              ...videoItem,
              thumbnailUrl: [this.cndPath, videoItem.thumbnailUrl].join(''),
              videoUrl: [this.cndPath, videoItem.videoUrl].join(''),
              completed: false,
            })),
            moduleCompleted: false,
            levelCompleted: false,
          }),
        );
        return moduleMapped;
      })
      .map(
        (moduleItem, index): CourseContentAdapted => ({
          ...moduleItem,
          moduleIndex: index,
        }),
      );
  }

  adaptTraineeProgress(
    progressResponse: ProgressResponse,
  ): TraineeModuleProgress[] {
    return progressResponse.levels.flatMap((level: Levels) =>
      level.modules.map(
        (moduleProgress: ModuleCompleted): TraineeModuleProgress => ({
          ...moduleProgress,
          videos: moduleProgress.videos.filter((video) => video.completedAt),
          levelId: level.levelId,
          levelCompleted: level.levelCompletedAt,
          isModuleComplete: Boolean(
            moduleProgress.videosCompletedAt &&
              moduleProgress.moduleCompletedAt,
          ),
        }),
      ),
    );
  }

  adaptModuleQuizAnswers(currentModuleQuiz: QuizConfig): Answer[][] {
    return currentModuleQuiz.questions.map((item): Answer[] => {
      return item.answers.map((answerItem: AnswerConfig): Answer => {
        return {
          ...answerItem,
          valid: answerItem.id === item.correctAnswerId,
          questionUsed: false,
        };
      });
    });
  }

  adaptModuleQuizQuestions(currentModuleQuiz: QuizConfig): Question[] {
    return currentModuleQuiz.questions.map(
      (item): { question: string; questionThumbnail: string } => {
        return {
          question: item.questionText,
          questionThumbnail: [this.cndPath, item.imageUrl].join(''),
        };
      },
    );
  }

  getSessionStorage() {
    const savedStorage = sessionStorage.getItem('appSessionState');

    const application: SessionStorage | null = savedStorage
      ? JSON.parse(savedStorage)
      : null;
    return application;
  }
}
