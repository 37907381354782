import { Component, OnInit } from '@angular/core';
import { SvgIconComponent } from '../shared/ui/utils/svg-icon/svg-icon.component';
import { HeaderComponent } from '../header/header.component';
import { Store } from '@ngrx/store';
import { AsyncPipe, CommonModule } from '@angular/common';
import * as selector from '../dashboard/state';
import { combineLatest, map } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import * as actions from '../dashboard/state/actions';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-quiz',
  standalone: true,
  imports: [
    SvgIconComponent,
    HeaderComponent,
    AsyncPipe,
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
  ],
  templateUrl: './quiz.component.html',
  styleUrl: './quiz.component.scss',
})
export class QuizComponent implements OnInit {
  quiz$ = this.store.select(selector.getModuleQuiz);
  moduleId$ = this.store.select(selector.getCurrentModuleId);
  activityTracker$ = this.store.select(selector.getActivityTracker);
  currentModuleIndex$ = this.store.select(selector.getCurrentModuleIndex);

  vm$ = combineLatest([
    this.quiz$,
    this.moduleId$,
    this.activityTracker$,
    this.currentModuleIndex$,
  ]).pipe(
    map(([quiz, moduleId, activityTracker, moduleIndex]) => {
      return {
        quiz,
        moduleId,
        activityTracker,
        moduleIndex,
      };
    }),
  );

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(
      actions.QuizPageActions.showModuleTabs({
        showTabs: false,
      }),
    );
  }

  nextQuestion() {
    this.store.dispatch(actions.QuizPageActions.nextQuestion());
  }

  submitQuizProgress(moduleId: number) {
    this.store.dispatch(
      actions.QuizPageActions.submitQuizProgress({ moduleId }),
    );
  }
  navigateHome() {
    this.store.dispatch(
      actions.QuizPageActions.quizRetakeNavigateHome({
        activity: {
          canDownloadCertificate: false,
          certificateLevelId: NaN,
          redirect: '/home',
          showQuizRetakeButton: false,
          text: '',
          type: 'retakeQuiz',
        },
      }),
    );
  }

  checkAnswer(answerIndex: number, questionId: number) {
    this.store.dispatch(
      actions.QuizPageActions.checkAnswer({
        answerIndex,
        answerId: questionId,
      }),
    );
  }
}
