import { createAction, props } from '@ngrx/store';
import { ActivityTracker } from '../models/state-models';

export const initApp = createAction('[App Load] App Load - Get Course');

export const moduleChange = createAction(
  '[Dashboard Page] Module Change',
  props<{ moduleIndex?: number; direction?: -1 | 1 }>(),
);

export const moduleChangeViaMenuRouteCheck = createAction(
  '[Dashboard Page] Module Change Via Menu - Route Check',
  props<{ level: string; moduleIndex: number; path: string | undefined }>(),
);
export const moduleChangeViaMenu = createAction(
  '[Dashboard Page] Module Change Via Menu',
  props<{ level: string; moduleIndex: number }>(),
);

export const trainingVideoPlayed = createAction(
  '[Dashboard Page] Training Video Played',
  props<{ videoId: number; videoAlreadyWatched: boolean }>(),
);

export const showModuleTabs = createAction(
  '[Dashboard] Module Tabs Updated - Dashboard',
  props<{ showTabs: boolean }>(),
);

export const activityTrackerDispatchedQuizRedirect = createAction(
  '[Dashboard Page] Activity Tracker Dispatched - Quiz Redirect',
  props<{ activity: ActivityTracker }>(),
);

export const activityTrackerDispatchedDownloadCertificate = createAction(
  '[Dashboard Page] Activity Tracker Dispatched - Download Certificate',
  props<{ levelId: number }>(),
);

export const activityTrackerDispatchedNextVideo = createAction(
  '[Dashboard Page] Activity Tracker Dispatched - Next Video In Module',
  props<{ activity: ActivityTracker }>(),
);

export const userClickedPlayVideo = createAction(
  '[Dashboard Page] User Clicked Play Video',
  props<{ videoIndex: number }>(),
);

export const userClosedVideo = createAction(
  '[Dashboard Page] User Closed Video',
);

export const userCompletedVideo = createAction(
  '[Dashboard Page] User Completed Video',
);

export const loadNextVideo = createAction(
  '[Dashboard Page] User finished video, loading next video',
  props<{ videoIndex: number }>(),
);

export const acceptCookies = createAction(
  '[Cookies Banner] Cookies Accepted',
);
export const updatePageHeader = createAction(
  '[Page Header] Update Page Header ',
  props<{ isCookiesPath: boolean }>(),
);
