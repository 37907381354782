import { createAction, props } from '@ngrx/store';
import { TraineeModuleProgress } from '../../services/models/trainee-progress.model';
import { SessionStorage } from '../../services/models/session-storage.model';
import { CourseModule } from '../models/state-models';

export const getCourseContentSuccess = createAction(
  '[On App Load Api] Load Success - Get Course',
  props<{
    courseModules: CourseModule[];
    sessionStorage: SessionStorage | null;
  }>(),
);

export const getCourseContentFailed = createAction(
  '[On App Load Api] Load Fail - Get Course',
  props<{ error: string }>(),
);

export const getTraineeProgressSuccess = createAction(
  '[On App Load Api] Load Success - Get Trainee Progress',
  props<{
    progressResults: TraineeModuleProgress[];
    userName: string;
    culture: string;
    traineeProgressId: string;
  }>(),
);

export const getTraineeProgressFailed = createAction(
  '[On App Load Api] Load Fail - Get Trainee Progress',
  props<{ error: string; culture: string }>(),
);
export const videoUpdateSuccess = createAction(
  '[Dashboard Api] Load Success - Video Progress',
);

export const videoUpdateFailed = createAction(
  '[Dashboard Api] Load Fail - Video Progress',
  props<{ error: string }>(),
);

export const DownloadCertificateSuccess = createAction(
  '[Dashboard Api] Load Success - Get Level Certificate',
  props<{ certificateBlob: Blob; levelId: number }>(),
);
export const DownloadCertificateFail = createAction(
  '[Dashboard Api] Load Fail - Get Level Certificate',
  props<{ error: string }>(),
);
