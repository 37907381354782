import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appResourceVideo]',
  standalone: true,
})
export class ResourceVideoDirectiveDirective {
  nativeElement = this.element.nativeElement;

  fullscreenApi =
    this.nativeElement.requestFullscreen ||
    this.nativeElement.webkitRequestFullScreen ||
    this.nativeElement.mozRequestFullScreen ||
    this.nativeElement.msRequestFullscreen;

  constructor(private element: ElementRef) {}

  @HostListener('play')
  onPlay() {
    this.fullscreenApi.call(this.nativeElement);
  }

  @HostListener('fullscreenchange')
  onFullScreen() {
    if (!window.document.fullscreenElement) {
      this.nativeElement.pause();
    }
  }
}
