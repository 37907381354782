import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private angularPlugin = new AngularPlugin();

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsightsConfig.instrumentationKey,
      extensions: [this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        },
      },
    },
  });

  constructor(private router: Router) {
    this.appInsights.loadAppInsights();
  }

  logError(error: Error) {
    this.appInsights.trackException({ exception: error });
  }
}
