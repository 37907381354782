import { Injectable } from '@angular/core';
import { AppInsightsService } from '../app-insights.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsErrorHandlerService {
  constructor(private applicationInsightService: AppInsightsService) {}

  errorHandler(error: Error | HttpErrorResponse) {
    this.logErrorOnApplicationInsight(error);
    this.logError(error);
  }

  private logErrorOnApplicationInsight(error: Error) {
    return this.applicationInsightService.logError(error);
  }

  private logError(error: Error | HttpErrorResponse) {
    console.error(error);
  }
}
