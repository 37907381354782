
<div class="inside-container" >
  <div class="error-container">
    @if ( error && (error.includes('Forbidden') || error.includes('LicenseExpired')) ) {
    <h1>
      It seems you do not have permission to access this page. Please contact support to resolve this issue.
    </h1>
    } @else {
    <h1>
      Oops there was an unexpected issue displaying the requested page. Please try again
      later.
    </h1>

    <a href="/home" class="button return-button">
      Return to the dashboard
    </a>
    }
    
    <button class="button return-button" (click)="logout()">
      Logout
    </button>
  </div>

</div>