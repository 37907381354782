import { Component } from '@angular/core';
import { SvgIconComponent } from '../../shared/ui/utils/svg-icon/svg-icon.component';
import { ResourceVideoDirectiveDirective } from '../../shared/directives/resource-video-directive.directive';
import { VideoConfig } from '../../shared/services/api/models/course-response';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as selector from '../state';
import { AsyncPipe, CommonModule } from '@angular/common';
import * as actions from '../state/actions';
import { VideoPlayerComponent } from '../video-player/video-player.component';

@Component({
  selector: 'app-video-resource',
  standalone: true,
  imports: [
    ResourceVideoDirectiveDirective,
    SvgIconComponent,
    AsyncPipe,
    CommonModule,
    VideoPlayerComponent,
  ],

  templateUrl: './video-resource.component.html',
  styleUrl: './video-resource.component.scss',
})
export class VideoResourceComponent {
  vm$: Observable<VideoConfig[]> = this.store.select(selector.getVideos);

  constructor(private store: Store) {}

  playVideo(index: number) {
    this.store.dispatch(
      actions.DashboardPageActions.userClickedPlayVideo({
        videoIndex: index,
      }),
    );
  }

  displayIndex(index: number) {
    return index + 1;
  }
}
