import { Component, OnInit } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import * as selectors from './state/dashboard.selector';
import * as actions from './state/actions';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { VideoResourceComponent } from './video-resource/video-resource.component';
import { SpinnerComponent } from '../shared/ui/elements/spinner/spinner.component';
import { ScrollTopButtonComponent } from '../shared/ui/elements/scroll-top-button/scroll-top-button.component';
import { DashboardPageActions } from './state';
import { ActivityTracker } from './state/models/state-models';
import { VideoPlayerComponent } from './video-player/video-player.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    HeaderComponent,
    VideoResourceComponent,
    SpinnerComponent,
    ScrollTopButtonComponent,
    AsyncPipe,
    VideoPlayerComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  constructor(private store: Store) {}

  activityTracker$ = this.store.select(selectors.getActivityTracker);
  dashboardState$ = this.store.select(selectors.getDashboardState);
  currentVideo$ = this.store.select(selectors.getCurrentVideo);
  vm$ = combineLatest([
    this.activityTracker$,
    this.dashboardState$,
    this.currentVideo$,
  ]).pipe(
    map(([activityTracker, dashboard, currentVideo]) => ({
      activityTracker,
      dashboard,
      currentVideo,
    })),
  );

  ngOnInit(): void {
    this.store.dispatch(
      DashboardPageActions.showModuleTabs({
        showTabs: true,
      }),
    );
  }

  nextActivityHandler(activity: ActivityTracker, quizRedirect: boolean) {
    if (quizRedirect === false && !activity.canDownloadCertificate) return;
    if (activity.canDownloadCertificate && !quizRedirect) {
      this.store.dispatch(
        actions.DashboardPageActions.activityTrackerDispatchedDownloadCertificate(
          {
            levelId: activity.certificateLevelId,
          },
        ),
      );
    } else if (activity.redirect) {
      this.store.dispatch(
        actions.DashboardPageActions.activityTrackerDispatchedQuizRedirect({
          activity,
        }),
      );
    } else if (activity.nextVideoIndex! >= 0) {
      this.store.dispatch(
        actions.DashboardPageActions.userClickedPlayVideo({
          videoIndex: activity.nextVideoIndex!,
        }),
      );
    }
  }
}
