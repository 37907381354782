import { Component, OnInit } from '@angular/core';
import { SvgIconComponent } from '../shared/ui/utils/svg-icon/svg-icon.component';
import { NavComponent } from './nav/nav.component';
import { ModuleTabsComponent } from './module-tabs/module-tabs.component';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import * as selectors from '../dashboard/state';
import { combineLatest, map } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [SvgIconComponent, NavComponent, ModuleTabsComponent, AsyncPipe],
})
export class HeaderComponent implements OnInit {
  currentModule$ = this.store.select(selectors.getCurrentModule);
  hasModuleTabs$ = this.store.select(selectors.showModuleTabs);
  getUserName$ = this.store.select(selectors.getUserName);
  isCookiePath$ = this.store.select(selectors.isCookiePath);

  vm$ = combineLatest([
    this.currentModule$,
    this.hasModuleTabs$,
    this.getUserName$,
    this.isCookiePath$,
  ]).pipe(
    map(([currentModule, hasTabs, userName, isCookiePath]) => {
      return {
        currentModule,
        hasTabs,
        userName,
        isCookiePath,
      };
    }),
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.title.subscribe((path) => {
      let isCookiesPath = false;
      if (path && path.includes('Cookies'))
        isCookiesPath = path.includes('Cookies');

      this.store.dispatch(
        selectors.DashboardPageActions.updatePageHeader({ isCookiesPath }),
      );
    });
  }
}
