export interface CookieTableDisplay {
  rows: CookieTableRow[];
  title: string;
  type: 'essential' | 'analytic';
}

export interface CookieTableRow {
  id: number;
  name: string;
  purpose: string;
  expires: string;
}

export const cookiesConfig: CookieTableDisplay[] = [
  {
    rows: [
      {
        id: 2,
        name: 'UnderstandingDyslexiaCookieConsent',
        purpose: 'Lets us know that you’ve saved your cookie consent settings.',
        expires: 'never',
      },
      {
        id: 1,
        name: 'ARRAffinitySameSite',
        purpose:
          'Ensures the link between clients and the server they connect to for the entirety of their session.',
        expires: 'never',
      },
      {
        id: 0,
        name: 'ARRAffinity',
        purpose:
          'Ensures the link between clients and the server they connect to for the entirety of their session.',
        expires: 'never',
      },
    ],

    title: 'Essential Cookies (necessary)',
    type: 'essential',
  },
];
