import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  Observable,
  retry,
  catchError,
  throwError,
  filter,
  switchMap,
} from 'rxjs';
import { AppInsightsErrorHandlerService } from '../../app-insights/error-handler/app-insights-error-handler.service';
import { AuthService } from '../../auth/auth.service';

export const errorInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const insightsErrors = inject(AppInsightsErrorHandlerService);

  return next(request).pipe(
    retry(1),
    catchError((error: HttpErrorResponse) => {
      if (
        error.status === HttpStatusCode.Unauthorized ||
        (request.url.endsWith('token') &&
          error.status === HttpStatusCode.BadRequest)
      ) {
        // retrigger login
        auth.login();

        if (request.url.endsWith('token')) {
          return throwError(() => error.error ?? error);
        }

        return auth.isAuthenticated$.pipe(
          filter(Boolean),
          switchMap(() => next(request)),
        );
      } else {
        router.navigate(['/error']);
        insightsErrors.errorHandler(error);
        return throwError(() => error.error ?? error);
      }
    }),
  );
};
