import { createAction, props } from '@ngrx/store';
import { ActivityTracker } from '../../../dashboard/state/models/state-models';

export const loadQuiz = createAction('[Quiz Page] Page Load');

export const showModuleTabs = createAction(
  '[Quiz Page] Module Tabs Updated - Quiz',
  props<{ showTabs: boolean }>(),
);

export const nextQuestion = createAction('[Quiz Page] Next Question');

export const checkAnswer = createAction(
  '[Quiz Page] Check Question Answer',
  props<{ answerIndex: number; answerId: number }>(),
);

export const submitQuizProgress = createAction(
  '[Quiz Page] Quiz Completed - Submit Progress',
  props<{ moduleId: number }>(),
);

export const quizRetakeNavigateHome = createAction(
  '[Quiz Page] Quiz Retake Complete- Navigate Home',
  props<{ activity: ActivityTracker }>(),
);
