import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selector from '../../dashboard/state';
import { SpinnerComponent } from '../../shared/ui/elements/spinner/spinner.component';
import { AsyncPipe } from '@angular/common';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [SpinnerComponent, AsyncPipe],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit {
  constructor(
    private store: Store,
    private auth: AuthService,
  ) {}

  errors$ = this.store.select(selector.getErrorMessage);
  error = '';

  ngOnInit() {
    this.errors$.subscribe((error) => {
      this.error = error;
    });
  }

  logout() {
    this.auth.logout();
  }
}
