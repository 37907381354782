import { environmentConfigFactory } from './models/environment-config.factory';
import {
  EnvironmentConfigSettings,
  ReadOnlyEnvironmentConfig,
} from './models/environment-config.model';

const CONFIG: EnvironmentConfigSettings = {
  production: false,
  appTitle: 'Nessy - Understanding Dyslexia',
  authAuthority: 'https://nessy-id-demo.nessy.com',
  clientId: 'nessy.understandingdyslexia.client',
  understandingDyslexiaApiPath: 'https://ud-api-demo.nessy.com/',
  appInsightsInstrumentationKey: '',
};

export const environment: ReadOnlyEnvironmentConfig = environmentConfigFactory(
  CONFIG,
);