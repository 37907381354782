/* eslint-disable @typescript-eslint/no-explicit-any */
export function saveAs(
  data: Blob,
  _document: Document,
  fileName: string,
  timeStamp: string,
) {
  const link = document.createElement('a');
  const downloadURL = URL.createObjectURL(data);

  if (typeof link.download === 'string') {
    link.href = downloadURL;
    link.download = fileName + '-' + timeStamp;
    link.click();
  }

  setTimeout(() => {
    _document.defaultView?.URL.revokeObjectURL(data as any);
  }, 100);
}
