import {
  EnvironmentConfigSettings,
  ReadOnlyEnvironmentConfig,
} from './environment-config.model';

export function environmentConfigFactory(
  config: EnvironmentConfigSettings,
): ReadOnlyEnvironmentConfig {
  return {
    settings: {
      ...config,
    },
    appInsightsConfig: {
      instrumentationKey: `${config.appInsightsInstrumentationKey}`,
    },
    api: {
      root: config.understandingDyslexiaApiPath,
      downloadCertificate: `${config.understandingDyslexiaApiPath}generateCertificate`,
      course: `${config.understandingDyslexiaApiPath}course`,
      progress: {
        trainee: `${config.understandingDyslexiaApiPath}progress`,
        videoWatched: `${config.understandingDyslexiaApiPath}progress/AddVideoWatched`,
        quizAnswered: `${config.understandingDyslexiaApiPath}progress/AddQuizAnswered`,
        reset: `${config.understandingDyslexiaApiPath}reset`,
      },

      ping: `${config.understandingDyslexiaApiPath}ping`,
    },
  };
}
