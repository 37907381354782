@if (vm$ | async; as vm) {
  @if (!vm.dashboard.loading) {
    <app-header></app-header>

    <div class="inside-container">
      <div class="next-activity">
        <button
          class="btn next-activity-btn"
          (click)="
            nextActivityHandler(
              vm.activityTracker,
              !vm.activityTracker.showQuizRetakeButton
            )
          "
        >
          @if (!vm.activityTracker.showQuizRetakeButton) {
            <strong>Next Activity:</strong>
          }
          {{ vm.activityTracker.text }}
        </button>
        @if (vm.activityTracker.showQuizRetakeButton) {
          <button
            class="btn retake-btn"
            (click)="
              nextActivityHandler(
                vm.activityTracker,
                vm.activityTracker.showQuizRetakeButton
              )
            "
          >
            Retake Quiz
          </button>
        }
      </div>

      @if (vm.dashboard.currentModule && vm.dashboard.currentModule.videos) {
        <app-video-resource></app-video-resource>
      }

      <app-scroll-top-button></app-scroll-top-button>
    </div>
    @if (vm.currentVideo.videoUrl) {
      <app-video-player></app-video-player>
    }
  } @else {
    <app-spinner></app-spinner>
  }
}
