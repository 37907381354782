import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { Store } from '@ngrx/store';
import * as selectors from './dashboard/state';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CookiesBannerComponent } from './core/cookies/cookies-banner/cookies-banner.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CookiesBannerComponent, AsyncPipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private auth: AuthService,
    private store: Store,
  ) {}
  cookiePreferences$ = this.store.select(selectors.getCookiePreferences);
  title = 'UnderstandingDyslexia';
  _destroy: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.auth.login();

    this.auth.isAuthenticated$
      .pipe(takeUntilDestroyed(this._destroy), distinctUntilChanged())
      .subscribe((isAuthenticated) => {
        if (isAuthenticated)
          this.store.dispatch(selectors.DashboardPageActions.initApp());
      });

    setTimeout(async () => {
      const { LazySvgSpriteSheetComponent } = await import(
        './core/lazy-svg-sprite-sheet/lazy-svg-sprite-sheet.component'
      );
      this.viewContainerRef.createComponent(LazySvgSpriteSheetComponent);
    });
  }
}
