import { Component } from '@angular/core';
import { SvgIconComponent } from '../../utils/svg-icon/svg-icon.component';

@Component({
  selector: 'app-scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.scss'],
  standalone: true,
  imports: [SvgIconComponent],
})
export class ScrollTopButtonComponent {
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
