
@if (currentVideo$ | async; as vm) {
  @if (vm.videoUrl) {
    <div class="video-container">
      <button
        class="btn closeBtn"
        (click)="close()"
      >
        <app-svg-icon [icon]="'icon-close'"></app-svg-icon>
      </button>
      <video
        #resourceVideo
        preload="none"
        height="0"
        width="0"
        [src]="vm.videoUrl"
        controls="true"
        autoplay="autoplay"
        controlsList="nodownload nofullscreen"
        [playsInline]="true"
        disablePictureInPicture
      ></video>
      @if (countdown !== null) {
        <div class="next-video-notice">Loading next video in {{ countdown }} seconds...</div>
      }
    </div>
  }
}
