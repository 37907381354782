import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuizComponent } from './quiz/quiz.component';
import { ErrorComponent } from './core/error-handler/error.component';
import { AuthUserGuard } from './core/route-guards/auth-user.guard';
import { ModuleQuizGuard } from './core/route-guards/module-quiz.guard';
import { CookiesComponent } from './core/cookies/cookies.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    title: 'Dashboard',
  },
  {
    path: 'home',
    title: 'Dashboard',
    component: DashboardComponent,
    canActivate: [AuthUserGuard],
  },
  {
    path: 'quiz',
    title: ' Quiz',
    component: QuizComponent,
    canActivate: [AuthUserGuard, ModuleQuizGuard],
  },
  {
    path: 'error',
    title: 'Error',
    component: ErrorComponent,
    canActivate: [AuthUserGuard],
  },
  {
    path: 'cookies',
    title: 'Cookies',
    component: CookiesComponent,
    canActivate: [AuthUserGuard],
  },
];
