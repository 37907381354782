@if (vm$ | async; as vm) {
  <header>
    <app-nav></app-nav>
    @if (vm.isCookiePath) {
      <section class="header-sub-section">
        <h1>Essential Cookies (necessary)</h1>
      </section>
    } @else {
      <div class="inside-container">
        <section class="header-sub-section">
          @if (vm.userName.length > 0) {
            <h3>Welcome {{ vm.userName }}</h3>
          }
          <h1>
            <strong>{{ vm.currentModule?.level }}:</strong>
            {{ vm?.currentModule?.levelName }}
          </h1>
          <h3>
            <strong>{{ vm.currentModule?.moduleTitle }}:</strong>
            {{ vm?.currentModule?.moduleName }}
          </h3>
        </section>
      </div>

      @if (vm.hasTabs) {
        <app-module-tabs></app-module-tabs>
      }
    }
  </header>
}
