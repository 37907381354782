import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { QueryResponse } from './response-templates/query-response';
import { CourseResponse } from './models/course-response';
import { CommandResponse } from './response-templates/command-response';
import { ProgressResponse } from './models/progress-response';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getDashboard(culture: string) {
    return this.http.get<QueryResponse<CourseResponse>>(
      environment.api.course,
      {
        params: {
          culture: culture,
        },
      },
    );
  }

  getTraineeProgress() {
    return this.http.get<QueryResponse<ProgressResponse>>(
      environment.api.progress.trainee,
    );
  }

  saveQuizProgress(moduleId: number, culture: string) {
    return this.http.post<CommandResponse<CourseResponse>>(
      environment.api.progress.quizAnswered,
      {},
      {
        params: {
          culture: culture,
          moduleId,
        },
      },
    );
  }
  saveVideoProgress(videoId: number, culture: string) {
    return this.http.post<CommandResponse<CourseResponse>>(
      environment.api.progress.videoWatched,
      {},
      {
        params: {
          culture: culture,
          videoId,
        },
      },
    );
  }

  downloadCertificate(levelId: number, culture: string) {
    return this.http.post(
      environment.api.downloadCertificate,
      { culture, levelId },
      { responseType: 'blob' },
    );
  }
}
