import { createAction, props } from '@ngrx/store';

export const quizUpdateSuccess = createAction(
  '[Quiz API] Quiz Update Success',
  props<{ moduleCompletedId: number }>(),
);

export const quizUpdateFailed = createAction(
  '[Quiz API] Quiz Update Failed',
  props<{ error: string }>(),
);
