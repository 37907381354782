import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Subscription, switchMap, tap } from 'rxjs';

const REQUESTED_ROUTE = 'requestedRouteBeforeAuth';
const ERROR_CYCLE_ONCE = 'errorCycleOneAttempt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(
    takeUntilDestroyed(),
    map((res) => res.isAuthenticated),
  );

  private checkAuthSub?: Subscription;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private router: Router,
  ) {}

  login() {
    this.checkAuthSub = this.oidcSecurityService
      .checkAuth()
      .pipe(
        tap(
          (res) => {
            if (res.isAuthenticated) {
              const requestedRoute = localStorage.getItem(REQUESTED_ROUTE);

              if (requestedRoute) {
                localStorage.removeItem(REQUESTED_ROUTE);
                this.router.navigateByUrl(requestedRoute);
              }
            } else {
              if (
                res.errorMessage?.startsWith(
                  'Error: authorizedCallback, token(s) validation failed',
                )
              ) {
                window.location.assign(
                  'https://id.nessy.com/diagnostics/customerdebug',
                );
              } else {
                this.goToIdentityLoginPage();
              }
            }
          },
          () => {
            if (!localStorage.getItem(ERROR_CYCLE_ONCE)) {
              localStorage.setItem(ERROR_CYCLE_ONCE, 'done');
              this.goToIdentityLoginPage();
            } else {
              localStorage.removeItem(ERROR_CYCLE_ONCE);
              console.error(
                'Identity Server failed to confirm your access to this application',
              );
              this.router.navigateByUrl('error');
            }
          },
        ),
      )
      .subscribe();
  }

  logout() {
    this.destroy();
    this.oidcSecurityService
      .preloadAuthWellKnownDocument()
      .pipe(switchMap(() => this.oidcSecurityService.logoffAndRevokeTokens()))
      .subscribe();
  }

  destroy() {
    if (sessionStorage.getItem('appSessionState'))
      sessionStorage.removeItem('appSessionState');
    if (localStorage.getItem('dashboard')) localStorage.removeItem('dashboard');
    if (localStorage.getItem('UD-token')) localStorage.removeItem('UD-token');
    if (this.checkAuthSub) this.checkAuthSub.unsubscribe();
  }

  goToIdentityLoginPage() {
    // store landing url in local storage
    localStorage.setItem(REQUESTED_ROUTE, window.location.pathname);

    this.oidcSecurityService.authorize();
  }

  bearerToken() {
    return this.oidcSecurityService.getAccessToken().pipe(
      tap((token) => {
        // Set the token in localStorage as a fallback if available
        if (token.length > 0) {
          localStorage.setItem('UD-token', token);
        }
      }),
      map((token) => token.length > 0 ? token : localStorage.getItem('UD-token'))  // Return the token from storage if null
    );
  }
}
