<app-header></app-header>

<div class="inside-container">
  <section class="cookies-table">
    @for (cookies of config; track $index) {
      <p>
        Understanding Dyslexia puts small files (known as ‘cookies’) on your
        computer.
        <br />

        These cookies are used across Understanding Dyslexia. We only set
        cookies when JavaScript is running in your browser and you’ve accepted
        them.
        <br />
        If you choose not to run Javascript, the information on this page will
        not apply to you.
        <br />
      </p>

      <table>
        <thead>
          <th>Name</th>
          <th>Purpose</th>
          <th>Expires</th>
        </thead>
        <tbody>
          @for (row of cookies.rows; track $index) {
            <tr>
              <td>{{ row['name'] }}</td>
              <td>{{ row['purpose'] }}</td>
              <td>{{ row['expires'] }}</td>
            </tr>
          }
        </tbody>
      </table>
    }
  </section>

  <div>
    <a [routerLink]="['/home']">Back Home</a>
  </div>
</div>
