import { Component } from '@angular/core';
import { cookiesConfig } from './config/cookies';
import { HeaderComponent } from '../../header/header.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-cookies',
  standalone: true,
  imports: [HeaderComponent, RouterLink],
  templateUrl: './cookies.component.html',
  styleUrl: './cookies.component.scss',
})
export class CookiesComponent {
  config = cookiesConfig;
}
