<app-header></app-header>

@if (vm$ | async; as vm) {
<div class="inside-container">
  @if (vm.quiz.quizQuestions.length) {
  <div class="question">
    <h3>Question {{ vm.quiz.currentQuestionIndex + 1 }}</h3>
    <img class="question-image" src="{{
            vm.quiz.quizQuestions[vm.quiz.currentQuestionIndex]
              .questionThumbnail
          }}" alt="Quiz Image" />

    <h2>
      {{ vm.quiz.quizQuestions[vm.quiz.currentQuestionIndex].question }}
    </h2>
  </div>

  <!-- answered -->
  <div class="answer-container">
    <div class="answers">
      @for (
      item of vm.quiz.quizAnswers[vm.quiz.currentQuestionIndex];
      track $index
      ) {
      <button type="button" [ngClass]="{
                correct: item.questionUsed && item.valid,
                incorrect: item.questionUsed && !item.valid,
              }" class="btn answer-btn" (click)="checkAnswer($index, item.id)" [innerHTML]="item.answerText">
      </button>
      }
    </div>

    <div class="result">
      @if (vm.quiz.hasUsedAnswers) {
      <p [ngClass]="{
                correct:
                  vm.quiz.quizAnswers[vm.quiz.currentQuestionIndex][
                    vm.quiz.currentAnswerIndex
                  ].valid,
                incorrect:
                  !vm.quiz.quizAnswers[vm.quiz.currentQuestionIndex][
                    vm.quiz.currentAnswerIndex
                  ].valid,
              }">
        {{
        vm.quiz.quizAnswers[vm.quiz.currentQuestionIndex][
        vm.quiz.currentAnswerIndex
        ].selectedText
        }}
      </p>
      }

      @if (vm.quiz.submitQuizProgress) {
      @if (vm.activityTracker.showQuizRetakeButton) {
      <button class="btn next-question-btn" (click)="navigateHome()">
        Back to Module {{ vm.moduleIndex + 1 }}
      </button>
      } @else {
      <button class="btn next-question-btn" (click)="submitQuizProgress(vm.moduleId)">
        Complete Quiz
      </button>
      }
      } @else if (vm.quiz.canMoveToNextQuesiton) {
      <button class="btn next-question-btn" (click)="nextQuestion()">
        Next Question
      </button>
      }
    </div>
  </div>

  } @else {
  <div class="error-container">
    <h3>It looks like there is an error, please try again later.</h3>
    <a class="return-button" [routerLink]="['/home']" routerLinkActive="router-link-active">
      Return to module
    </a>
  </div>
  }
</div>
}