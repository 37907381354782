@if (vm$ | async; as vm) {
  <div class="pagination">
    <button
      [ngClass]="{ disabled: vm.currentModuleIndex === 0 }"
      class="page-step-back"
      data-shown="1"
      (click)="moduleChange(-1)"
    >
      <app-svg-icon
        [icon]="'icon-back-arrow'"
        [title]="'Home Link'"
        class="icon"
      ></app-svg-icon>
    </button>

    @for (item of vm.modules; track $index) {
      <button
        class="page-btn"
        [ngClass]="{
          completed: item.moduleCompleted,
          active: item.moduleIndex === vm.currentModuleIndex,
        }"
        (click)="moduleChange(undefined, item.moduleIndex)"
      >
        @if (item.moduleCompleted) {
          <app-svg-icon
            [icon]="'icon-tick'"
            [title]="'Completed'"
            class="completed"
          ></app-svg-icon>
        } @else {
          {{ displayIndex(item.moduleIndex) }}
        }
      </button>
    }
    <button
      [ngClass]="{ disabled: vm.currentModuleIndex === vm.moduleCount - 1 }"
      class="page-step-next"
      data-shown="5"
      (click)="moduleChange(1)"
    >
      <app-svg-icon
        [icon]="'icon-forward-arrow'"
        [title]="'Home Link'"
        class="icon"
      ></app-svg-icon>
    </button>
  </div>
}
