import { Component } from '@angular/core';
import { SvgIconComponent } from '../../shared/ui/utils/svg-icon/svg-icon.component';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import * as selector from '../../dashboard/state';
import { combineLatest, map } from 'rxjs';
import { DashboardPageActions } from '../../dashboard/state/actions';
import { AuthService } from '../../core/auth/auth.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Certificate } from '../../dashboard/state/models/state-models';
import { policyLinksConfig } from './config/nav-config';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgIconComponent, RouterLink],
})
export class NavComponent {
  constructor(
    private store: Store,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}
  isShowing!: boolean;
  policyLinks = policyLinksConfig

  displayingNav = new BehaviorSubject<boolean>(false);

  modules$ = this.store.select(selector.getAllModules);
  currentModule$ = this.store.select(selector.getCurrentModule);
  currentLevel$ = this.store.select(selector.getCurrentLevel);
  certificates$ = this.store.select(selector.getCertificates);

  vm$ = combineLatest([
    this.modules$,
    this.currentModule$,
    this.currentLevel$,
    this.certificates$,
  ]).pipe(
    map(([modules, currentModule, currentLevel, certificates]) => ({
      modules,
      currentModule,
      currentLevel,
      certificates,
    })),
  );

  toggleSideNav() {
    this.isShowing = !this.isShowing;
    this.displayingNav.next(this.isShowing);
  }

  moduleChange(level: string, index: number) {
    const { routeConfig } = this.activatedRoute.snapshot;
    this.store.dispatch(
      DashboardPageActions.moduleChangeViaMenuRouteCheck({
        level: level,
        moduleIndex: index,
        path: routeConfig?.path,
      }),
    );
    this.toggleSideNav();
  }

  logout() {
    this.auth.logout();
    this.toggleSideNav();
  }

  navigate() {
    this.router.navigate(['/home']);
    this.toggleSideNav();
  }

  getCertificate(levelId: number) {
    this.store.dispatch(
      selector.DashboardPageActions.activityTrackerDispatchedDownloadCertificate(
        { levelId },
      ),
    );
  }

  areCertificatesAvailable(certificates: Certificate[]): boolean {
    return certificates.some(
      (certificate: Certificate) => certificate.isCertificateAvailable,
    );
  }
}
