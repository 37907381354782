/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';

import * as selectors from '../../dashboard/state/index';
import { CourseModule } from '../../dashboard/state/models/state-models';

export const ModuleQuizGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);

  const currentModule$: Observable<CourseModule | null> = store.select(
    selectors.getCurrentModule,
  );

  return currentModule$.pipe(
    filter(
      (currentModule) =>
        currentModule === null ||
        (Object.keys(currentModule).length > 0 && currentModule !== null),
    ),
    map((currentModule) => {
      if (currentModule !== null) {
        const allVideosWatched = currentModule?.videos.every(
          (item) => item.completed,
        );

        const firstQuiz = allVideosWatched && !currentModule?.moduleCompleted;
        const retakeQuiz = allVideosWatched && currentModule?.moduleCompleted;

        return firstQuiz || retakeQuiz ? true : router.createUrlTree(['']);
      } else return router.createUrlTree(['']);
    }),
  );
};
