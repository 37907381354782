/* eslint-disable @typescript-eslint/no-unused-vars */
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { inject } from '@angular/core';
import { catchError, filter, map, Observable, of, timeout } from 'rxjs';

export const AuthUserGuard: CanActivateFn = (
  route,
  state,
): Observable<boolean> => {
  const auth = inject(AuthService);
  return auth.isAuthenticated$.pipe(
    map((isAuthenticated) => isAuthenticated),
    filter(Boolean),
    timeout(10000),
    catchError(() => of(false)),
  );
};
