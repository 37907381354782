import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import * as selectors from '../../../dashboard/state';
import { BannerComponent } from '../../../shared/ui/elements/banner/banner.component';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-cookies-banner',
  standalone: true,
  imports: [RouterLink, BannerComponent, AsyncPipe],
  templateUrl: './cookies-banner.component.html',
  styleUrl: './cookies-banner.component.scss',
})
export class CookiesBannerComponent {
  cookieAccepted$ = this.store.select(selectors.getCookiePreferences);

  constructor(private store: Store) {}

  acceptCookies() {
    this.store.dispatch(selectors.DashboardPageActions.acceptCookies());
    localStorage.setItem('UnderstandingDyslexiaCookieConsent', 'true');
  }
}
