@if ((cookieAccepted$ | async) === false) {
<app-banner>
  <section class="message">
    <h1 class="h3">This Website Uses Cookies</h1>
    <p>
      Nessy uses essential cookies to make Understanding Dyslexia work.
      <br />
      By clicking accept you accept our use of cookies on this website.
    </p>
  </section>

  <section class="buttons">
    <button (click)="acceptCookies()">Accept</button>
    <a [routerLink]="'/cookies'">View Cookies</a>
  </section>
</app-banner>
}
