import {
  ApplicationConfig,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAuth } from 'angular-auth-oidc-client';
import { AuthService } from './core/auth/auth.service';
import { tokenInterceptor } from './core/auth/token.interceptor';
import { environment } from '../environments/environment';
import { errorInterceptor } from './core/error-handler/interceptor/error.interceptor';
import { courseDashboardReducer } from './dashboard/state/dashboard.reducer';
import { Action, ActionReducer, MetaReducer, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideEffects } from '@ngrx/effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as dashboardEffects from './dashboard/state/dashboard.effects';
import { CourseState } from './dashboard/state/models/state-models';

// Sync the store state with localStorage
export function localStorageSyncReducer(
  reducer: ActionReducer<{ dashboard: CourseState }, Action<string>>,
): ActionReducer<{ dashboard: CourseState }, Action<string>> {
  return localStorageSync({ keys: ['dashboard'], rehydrate: true })(reducer);
}

const metaReducers: [MetaReducer<{ dashboard: CourseState }, Action<string>>] =
  [localStorageSyncReducer];

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([tokenInterceptor, errorInterceptor])), //-NOTE: Order of interceptors is important
    provideAuth({
      config: {
        authority: environment.settings.authAuthority,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.settings.clientId,
        scope: 'openid UnderstandingDyslexia offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        // logLevel: LogLevel.Debug,
      },
    }),
    // { provide: AbstractLoggerService, useClass: ConsoleLogger },
    provideStore({ dashboard: courseDashboardReducer }, { metaReducers }),
    provideEffects(dashboardEffects.DashboardEffects),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      features: {
        pause: true,
        lock: true,
        persist: true,
        export: true,
        import: 'custom',
      },
    }),
    AuthService,
  ],
};
