@if (vm$ | async; as vm) {
  <div class="nav-background">
    <nav>
      <menu
        class="nav-list"
        role="menu"
        aria-label="Menu"
      >
        <li
          role="menuitem"
          class="nav-toggle"
          aria-label="Nav Toggle"
        >
          <a
            href="/home"
            aria-label="Home"
            class="nessy-icon"
          >
            <app-svg-icon class="nessy-logo"></app-svg-icon>
          </a>
          <button
            type="button"
            class="toggle"
            (click)="toggleSideNav()"
            [tabIndex]="1"
            id="toggle"
            aria-label="Toggle Button"
          >
            <h3 class="button-label">Menu</h3>

            <app-svg-icon
              [icon]="'icon-hamburger'"
              [title]="'Navigation Menu'"
              class="icon"
            ></app-svg-icon>
          </button>
        </li>

        <aside
          class="sidebar"
          [ngClass]="{ visible: isShowing }"
        >
          <nav class="sidebar__menu">
            <button
              (click)="navigate()"
              (keydown)="navigate()"
            >
              <app-svg-icon
                [icon]="'icon-home'"
                [title]="'Home Link'"
                class="icon"
              ></app-svg-icon>
              Home
            </button>
            <ul>
              <span class="nav-header">
                <app-svg-icon
                  [icon]="'icon-resources'"
                  [title]="'Modules'"
                  class="icon"
                ></app-svg-icon>
                Modules
              </span>

              @for (item of vm.modules; track $index) {
                <li>
                  <button
                    class="nav-btn module-btn"
                    (click)="moduleChange(vm.currentLevel, $index)"
                  >
                    {{ item.moduleName }}
                  </button>
                </li>
              }
            </ul>
            <ul>
              <span class="nav-header">
                <app-svg-icon
                  [icon]="'icon-report'"
                  [title]="'Certificates'"
                  class="icon"
                ></app-svg-icon>
                Certificates
              </span>
              @for (cert of vm.certificates; track $index) {
                @if (cert.isCertificateAvailable) {
                  <li>
                    <button (click)="getCertificate(cert.levelId)">
                      {{ cert.levelName }} - {{ cert.level }}
                    </button>
                  </li>
                }
              }
              @if (!areCertificatesAvailable(vm.certificates)) {
                <li>
                  <span>No Certificates Available</span>
                </li>
              }
            </ul>
            <button
              class="nav-btn"
              (click)="logout()"
            >
              <app-svg-icon
                [icon]="'icon-logout'"
                [title]="'Logout'"
                class="Logout"
              ></app-svg-icon>
              Logout
            </button>
          </nav>

          <div class="terms-and-privacy">
            @for (link of policyLinks; track $index) {
              @if (link.isRouterLink) {
                <a
                  [routerLink]="link.link"
                  (click)="toggleSideNav()"
                >
                  {{ link.text }}
                </a>
              } @else {
                <a href="{{ link.link }}">{{ link.text }}</a>
              }
            }
          </div>

          <button
            type="button"
            class="sidebar__close"
            (click)="toggleSideNav()"
          >
            <label for="menu-control"></label>
          </button>
        </aside>
      </menu>

      <app-svg-icon class="{{ vm.currentModule?.levelIcon }}"></app-svg-icon>
    </nav>
  </div>
}
